import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/gamification.scss";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";

function GamificationPage({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const strapiHost = process.env.GATSBY_API_URL;
  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          gamification(locale: "${pageContext.intl.language}") {
            title_gamification
            description_gamification
            description
            img {
              url
            }
            realizations_gamification {
              description
              img {
                url
              }
            }
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.gamification);
      setLoading(false);
    });
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout contactUs={false} initial={false} nav={false}>
            <div>
              <div className="gamification__wrapper">
                <div className="gamification__first">
                  <div className="gamification__left">
                    <img src={`${strapiHost}${data.img.url}`} />
                  </div>
                  <div className="gamification__right">
                    <h1 className="gamification__title">
                      {data.title_gamification}
                    </h1>
                    <p className="gamification__description">
                      {data.description_gamification}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
          <div className="gamification__section2">
            <Layout contactUs={false} nav={false} initial={false}>
              <p className="gamification__description2">{data.description}</p>
              <div className="gamification__rectangles">
                {data.realizations_gamification.map(({ description, img }) => (
                  <div className="gamification__rec">
                    <img
                      src={`${strapiHost}${img.url}`}
                      className="homepage__img2"
                    />
                    <p className="gamification__desc3">{description}</p>
                  </div>
                ))}
              </div>
            </Layout>
          </div>
        </ShowProvider>
      )}
    </>
  );
}

export default GamificationPage;
